import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import media from "styled-media-query";
import { Box } from "atomic-layout";
import { Page, Title, Carousel } from "../components";

const Header = styled(Box)`
  ${media.lessThan("medium")`
    padding-top: 60px;
  `}
`;

const TitleHolder = styled(Box)`
  ${media.lessThan("medium")`
    padding: 60px 0 !important;

    ${Title} {
      font-size: 50px;
    }
  `}
`;

const ImageContainer = styled(Box)`
  ${media.lessThan("medium")`
    & > div {
      width: 100% !important;
      position: relative !important;
      padding: 30px 5% !important;
    }
  `}
`;

const SecondBox = styled(Box)`
  ${media.lessThan("medium")`
    & > div {
      flex-flow: row wrap;
      padding-top: 30px !important;
      padding-bottom: 60px !important;
    }

    .box-one, .box-two {
      width: 100% !important;
      padding: 0;
    }

    ${Title} {
      font-size: 36px;
      margin-bottom: 30px;
    }
  `}
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  font-family: "Raleway";
  text-transform: uppercase;
  color: #262632;
  text-decoration: none;
  font-weight: 600;
  padding: 30px;
  display: flex;
  position: relative;

  :hover:after {
    width: 100%;
  }

  :after {
    content: "";
    width: ${({ active }) => (active ? "100%" : "0%")};
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #5273b0;
    transition: all 400ms ease;
  }
`;

const Texts = styled.div`
  p {
    color: #6c6c6c;
    :not(:first-of-type) {
      margin-top: 30px;
    }
  }
`;

const HostPage = ({ data, pageContext }) => {
  const { node } = data.allContentfulHospedagem.edges[0];

  const all = data.allHospedagens.edges;

  return (
    <Page
      title={node.titulo}
      description={node.resumo.childMarkdownRemark.html}
      backgroundColor="white"
    >
      <Header
        flex
        width="100%"
        style={{ backgroundColor: "#E9ECEC" }}
        align="center"
        justifyContent="center"
      >
        <Box width="100%" maxWidth={1024} justifyContent="space-between" flex className="container">
          
          {all.map(({node}) => (
            <Box>
              <StyledLink to={`/hospedagem/${node.slug}`} active={node.slug === pageContext.slug}>
                {node.titulo}
              </StyledLink>
            </Box>
          ))}
        </Box>
      </Header>

      <TitleHolder width="100%" flex align="center" justifyContent="center" paddingVertical={100}>
        <Title color="#27494A">{node.titulo}</Title>
      </TitleHolder>

      <ImageContainer style={{ position: "relative" }}>
        <Box
          as={GatsbyImage}
          image={getImage(node.imagemDeCapa.gatsbyImageData)}
          title={node.imagemDeCapa.title}
          alt={node.imagemDeCapa.description}
          width="100%"
          height="80vh"
        />

        <Box
          style={{ background: "white", position: "absolute", bottom: 0, left: 0 }}
          padding={100}
          paddingLeft="10%"
          width="50%"
        >
          <Texts dangerouslySetInnerHTML={{ __html: node.resumo.childMarkdownRemark.html }} />
        </Box>
      </ImageContainer>

      <SecondBox flex>
        <Box width="90%" maxWidth={1320} margin="auto" paddingVertical={200} flex>
          <Box width="40%" className="box-one">
            <Title as="h2" variant="h2" color="#27494A">
              {node.subtitulo}
            </Title>
          </Box>
          <Box width="60%" paddingLeft="10%" className="box-two">
            <Box
              as={Texts}
              color="#6C6C6C"
              dangerouslySetInnerHTML={{ __html: node.descricao.childMarkdownRemark.html }}
            />
          </Box>
        </Box>
      </SecondBox>

      <Box marginBottom={80}>
        <Carousel
          items={node.galeria.map((item) => ({
            id: item.id,
            image: item,
            title: item.title,
          }))}
        />
      </Box>

      {/* <Structure>
        <Title as="h3" variant="h2">
          Conheça nossa estrutura
        </Title>
        <GatsbyImage image={getImage(node.imagemDeCapa.gatsbyImageData)} />
      </Structure> */}
    </Page>
  );
};

export const query = graphql`
  query($slug: String!) {
    allHospedagens: allContentfulHospedagem(filter: { node_locale: { eq: "en-US" }}) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    allContentfulHospedagem(filter: { node_locale: { eq: "en-US" }, slug: { eq: $slug } }) {
      edges {
        node {
          id
          galeria {
            id
            gatsbyImageData(layout: FULL_WIDTH)
            title
            description
          }
          descricao {
            childMarkdownRemark {
              html
            }
          }
          imagemDeCapa {
            gatsbyImageData(layout: FULL_WIDTH)
            title
            description
          }
          titulo
          subtitulo
          slug
          resumo {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default HostPage;
